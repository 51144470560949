body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 1;
  }
}
@media (min-width: 768px) {
  .card-columns {
    column-count: 2;
  }
}

@media (min-width: 992px) {
  .card-columns {
    column-count: 3;
  }
}

@media (min-width: 1200px) {
  .card-columns {
    column-count: 3;
  }
}
